import {
    AddItemsMutation as AddItemsMutationResponse,
    AddItemsMutationVariables,
    ItemPropsFragment,
    useAddItemsMutation,
} from './reactQueryHooks';

import useGetLists from 'apis/list/useGetLists';

import { logAddToCart } from 'utils/analytics';
import updateHeader from 'utils/cart-header-util';

import useLocale from 'hooks/useLocale';
import useMonitoring from 'hooks/useMonitoring';

export type AddToCartSource =
    | 'favourites_add_all'
    | 'favourites'
    | 'received_add_all'
    | 'received';

type InitialItem = {
    itemNo: string;
    quantity: number;
};

type CommonVariables = {
    languageCode: string;
};
// Omit these common variables, we provide them inside the hooks instead
type OmitCommon<T> = Omit<T, keyof CommonVariables>;

/*
  Returns params and Custom value
  params: array of Items which includes itemNo, quantity and unit price excl tax
  custom: Cart id and Cart value local i.e total unit price of the items added to the cart
  */
const getCartValues = (res: AddItemsMutationResponse, listId: string) => {
    if (!res.addItems) {
        return {
            params: {
                items: [],
            },
            custom: {
                cart_value_local: 'NaN',
                cart_id: '',
            },
        };
    }
    const { context, items } = res.addItems;
    return {
        params: {
            items: items.map((item: InitialItem) => {
                const product = items.find(
                    (i: ItemPropsFragment) => i.itemNo === item.itemNo
                );
                return {
                    id: item.itemNo,
                    quantity: item.quantity,
                    price: product?.regularPrice?.unit?.exclTax,
                };
            }),
        },
        custom: {
            list_id: listId,
            cart_id: `${context?.userId}-${context?.retailId}-${
                context?.isAnonymous ? 'anonymous' : 'non-anonymous'
            }`,
            cart_value_local: items
                .reduce((acc, item: InitialItem) => {
                    const product = items.find(
                        (i: ItemPropsFragment) => i.itemNo === item.itemNo
                    );
                    return (
                        acc +
                        (product
                            ? item.quantity *
                              product?.regularPrice?.unit?.exclTax
                            : 0)
                    );
                }, 0)
                .toFixed(2),
        },
    };
};

export const useAddToCart = () => {
    const { currentList } = useGetLists();
    const { mutateAsync, isLoading } = useAddItemsMutation();
    const { language: languageCode, country } = useLocale();
    const { setContext } = useMonitoring();

    setContext('latestCartCall', {
        name: 'addItems',
    });

    return {
        addToCart: async (
            variables: OmitCommon<AddItemsMutationVariables>,
            source: AddToCartSource
        ) => {
            const res = await mutateAsync({
                ...{ languageCode },
                ...variables,
            });
            if (res?.addItems) {
                updateHeader(
                    res?.addItems.items.reduce(
                        (curr, item) => curr + item.quantity,
                        0
                    ),
                    languageCode,
                    country
                );
                logAddToCart(
                    getCartValues(res, currentList?.listId ?? ''),
                    source
                );
            }
            return res;
        },
        isLoading,
    };
};
