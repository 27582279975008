import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { useFetchData } from 'apis/cart/fetcher';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
};

/** The necessary input when adding an item. */
export type AddItemInput = {
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID'];
    /** The item quantity. */
    quantity: Scalars['Int'];
};

/** Input for AddPlanners. */
export type AddPlannersInput = {
    /** An array of planner codes. Must be alphanumeric with a length of 6 characters. */
    codes: InputMaybe<Array<Scalars['String']>>;
};

/** Return type of AddPlanners. */
export type AddPlannersPayload = {
    __typename?: 'AddPlannersPayload';
    /** The planners that were added. */
    planners: Array<Planner>;
};

/** Additional purchase. */
export type AdditionalPurchase = {
    __typename?: 'AdditionalPurchase';
    /**
     * The unit.
     * @deprecated This is an experimental feature that can be removed without notice. Use with caution.
     */
    unit: Maybe<Scalars['String']>;
    /**
     * The value.
     * @deprecated This is an experimental feature that can be removed without notice. Use with caution.
     */
    value: Maybe<Scalars['String']>;
};

/** The input when setting an address. */
export type AddressInput = {
    /** The postal code. */
    postalCode: Scalars['String'];
    /** The state code. For example NSW or QLD in Australia. */
    state: InputMaybe<Scalars['String']>;
};

/** The assembly service. */
export type AssemblyService = {
    __typename?: 'AssemblyService';
    /** The config. */
    config: Maybe<AssemblyServiceConfig>;
    /**
     * The configuration.
     * @deprecated Type is misspelled please use config instead.
     */
    configuration: Maybe<AssemblyServiceConfiguraion>;
    /** Is assembly service available for any of the items? */
    isAvailable: Scalars['Boolean'];
};

/** The base price type for assembly service. */
export enum AssemblyServiceBasePriceType {
    /** BASE type. */
    Base = 'BASE',
    /** MINIMUM type. */
    Minimum = 'MINIMUM',
}

/** The assembly service config. */
export type AssemblyServiceConfig = {
    __typename?: 'AssemblyServiceConfig';
    /** The base price. */
    basePrice: Scalars['Float'];
    /** The base price type. */
    basePriceType: AssemblyServiceBasePriceType;
    /** The id. */
    id: Scalars['ID'];
    /** The provider. */
    provider: Scalars['String'];
    /** The type. */
    type: Scalars['String'];
};

/** The assembly service configuration. */
export type AssemblyServiceConfiguraion = {
    __typename?: 'AssemblyServiceConfiguraion';
    /** The base price. */
    basePrice: Scalars['Float'];
    /** The base price type. */
    basePriceType: AssemblyServiceBasePriceType;
    /** The id. */
    id: Scalars['ID'];
    /** The provider. */
    provider: Scalars['String'];
    /** The type. */
    type: Scalars['String'];
};

/** The assembly service price. */
export type AssemblyServicePrice = {
    __typename?: 'AssemblyServicePrice';
    /** The sub total price. */
    subTotal: AssemblyServiceSubTotalPrice;
    /** The unit price. */
    unit: AssemblyServiceUnitPrice;
};

/** The assembly service sub total price. */
export type AssemblyServiceSubTotalPrice = {
    __typename?: 'AssemblyServiceSubTotalPrice';
    /** Excluding tax. */
    exclTax: Maybe<Scalars['Float']>;
    /** Including tax. */
    inclTax: Maybe<Scalars['Float']>;
    /** Tax. */
    tax: Maybe<Scalars['Float']>;
};

/** The assembly service unit price. */
export type AssemblyServiceUnitPrice = {
    __typename?: 'AssemblyServiceUnitPrice';
    /** Excluding tax. */
    exclTax: Maybe<Scalars['Float']>;
    /** Including tax. */
    inclTax: Maybe<Scalars['Float']>;
    /** Tax. */
    tax: Maybe<Scalars['Float']>;
};

/** The availability. */
export type Availability = {
    __typename?: 'Availability';
    /** Available for click and collect? */
    clickAndCollect: Maybe<Scalars['Boolean']>;
    /** Available for home delivery? */
    homeDelivery: Maybe<Scalars['Boolean']>;
    /**
     * The maximum quantity a user can buy in a single purchase.
     * Market online range specialists are responsible to set the limit per item.
     * If no limit is set by the markets, we will default to 999 quantity limit.
     */
    quantityLimit: Scalars['Int'];
};

/** The base interface and common denominator for an item. */
export type BaseItem = {
    /** The fees, e.g. "eco", "weee" */
    fees: Maybe<Fees>;
    /** Is the item a family item or not? */
    isFamilyItem: Scalars['Boolean'];
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID'];
    /** The quantity. */
    quantity: Scalars['Int'];
    /** Type of item, e.g. "ART", "SPR". */
    type: ItemType;
};

/** The business structure. */
export type BusinessStructure = {
    __typename?: 'BusinessStructure';
    /** The home furnishing business name. */
    homeFurnishingBusinessName: Scalars['String'];
    /** The home furnishing business number. */
    homeFurnishingBusinessNo: Scalars['String'];
    /** The product area name. */
    productAreaName: Scalars['String'];
    /** The product area number. */
    productAreaNo: Scalars['String'];
    /** The product range area name. */
    productRangeAreaName: Scalars['String'];
    /** The product range area number. */
    productRangeAreaNo: Scalars['String'];
};

/**
 * A shopping cart is a container for items. Based on the current retail id, the items along with the quantity
 * are used to calculate price.
 *
 * Note! If you do a mutation and the response type is a Cart, returning just the
 * context requires no extra resources to be fetched from the server. So if the response is of no importance to you,
 * other than knowing if an operation was successful or not, the context alone is what you want to set as return value.
 */
export type Cart = {
    __typename?: 'Cart';
    /** The assembly service. */
    assemblyService: Maybe<AssemblyService>;
    /**
     * The cart checksum changes when the cart is mutated i.e. items are added, quantity is updated or a discount
     * code is added. The checksum can be used to track cart updates, e.g.
     * "0aplaVsWzPkgU8cnpIBYa42H0NF3LkVv".
     */
    checksum: Scalars['String'];
    /** The context. */
    context: Context;
    /** The coupon. */
    coupon: Maybe<Coupon>;
    /**
     * The currency code for pricing. Based on ISO 4217, which represents currency as a
     * three-letter alphabetic code ("EUR", "SEK").
     */
    currency: Maybe<Scalars['String']>;
    /** The total family price. */
    familyTotalPrice: Maybe<FamilyTotalPrice>;
    /** A list of all items in the cart. */
    items: Array<Item>;
    /** The package weight of the items in the cart. For now we only provide the formatted metric weight. */
    packageWeight: Maybe<Scalars['String']>;
    /** A list of all planners in the cart. */
    planners: Array<Planner>;
    /** The quantity of all items in the cart (excluding child items). */
    quantity: Scalars['Int'];
    /** The total regular price. */
    regularTotalPrice: Maybe<RegularTotalPrice>;
    /**
     * The date when the cart was last modified. Format is ISO 8601 and the time zone is coordinated universal
     * time (UTC), YYYY-MM-DDThh:mm:ssZ, e.g. "2019-02-06T21:56:00Z".
     */
    updated: Scalars['String'];
};

/** A child item is a sub item that belongs to a parent item and cannot have it's own children. */
export type ChildItem = BaseItem & {
    __typename?: 'ChildItem';
    /** The family price. */
    familyPrice: Maybe<FamilyChildItemPrice>;
    /** The fees, e.g. "eco", "weee" */
    fees: Maybe<Fees>;
    /** Is the item a family item or not? */
    isFamilyItem: Scalars['Boolean'];
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID'];
    /** The package weight of the items in the cart. For now we only provide the formatted metric weight. */
    packageWeight: Maybe<Scalars['String']>;
    /** The product information for child item */
    product: Maybe<Product>;
    /** The quantity of a child item (does not effect the parent item quantity). */
    quantity: Scalars['Int'];
    /** The regular price. */
    regularPrice: RegularChildItemPrice;
    /** Type of item, e.g. "ART", "SPR". */
    type: ItemType;
};

/** Return type of ClearPlanners. */
export type ClearPlannersPayload = {
    __typename?: 'ClearPlannersPayload';
    /** The planners that were cleared. */
    planners: Array<Planner>;
};

/** The context. */
export type Context = {
    __typename?: 'Context';
    /** Did the employee agree to the terms and conditions? */
    employeeDiscountAgreedTerms: Maybe<Scalars['Boolean']>;
    /** In case the user is an IKEA employee this will be populated with their id. */
    employeeId: Maybe<Scalars['String']>;
    /** Is user anonymous or not? */
    isAnonymous: Scalars['Boolean'];
    /** A request id */
    requestId: Scalars['String'];
    /** A retail unit id. */
    retailId: Scalars['String'];
    /** The user ID. */
    userId: Scalars['ID'];
};

/** The coupon. */
export type Coupon = {
    __typename?: 'Coupon';
    /** The coupon code. */
    code: Scalars['String'];
    /** A description of the coupon. Can only be populated when the cart is not empty and the languageCode is set */
    description: Maybe<Scalars['String']>;
    /** Valid from (descriptive date field). */
    validFrom: Maybe<Scalars['String']>;
    /** Valid to (descriptive date field). */
    validTo: Maybe<Scalars['String']>;
    /** The validation codes. Codes for why a coupon is not applied. */
    validationCodes: Maybe<Array<CouponValidationCode>>;
};

/** The coupon validation code. */
export enum CouponValidationCode {
    /** The coupon code is expired. */
    CouponExpired = 'COUPON_EXPIRED',
    /** The coupon has not yet started. */
    CouponNotStarted = 'COUPON_NOT_STARTED',
    /** The coupon has already been redeemed. */
    CouponRedeemed = 'COUPON_REDEEMED',
    /**
     * The coupon code is expired.
     * @deprecated Deprecated for clarity in favour of COUPON_EXPIRED.
     */
    Expired = 'EXPIRED',
    /** Some internal issue happened and we were not able to validate the coupon code. This can happen if the discount system is down. */
    InternalError = 'INTERNAL_ERROR',
    /**
     * The coupon code is invalid.
     * @deprecated Deprecated for clarity in favour of INVALID_COUPON_CODE.
     */
    Invalid = 'INVALID',
    /** The context is invalid. For example if the coupon is only applied for certain items but they are not in the cart. */
    InvalidContext = 'INVALID_CONTEXT',
    /** The coupon code is invalid. */
    InvalidCouponCode = 'INVALID_COUPON_CODE',
    /**
     * The coupon has not yet started.
     * @deprecated Deprecated for clarity in favour of COUPON_NOT_STARTED.
     */
    NotStarted = 'NOT_STARTED',
    /**
     * The coupon has already been redeemed.
     * @deprecated Deprecated for clarity in favour of COUPON_REDEEMED.
     */
    Redeemed = 'REDEEMED',
}

/** The discount. */
export type Discount = {
    __typename?: 'Discount';
    /** The discount amount. */
    amount: Scalars['Float'];
    /** The discount code. */
    code: Maybe<Scalars['String']>;
    /** The discount description. */
    description: Maybe<Scalars['String']>;
    /**
     * The discount kind.
     * @deprecated This has never been implemented and should not be used.
     */
    kind: DiscountKind;
    /** The discount percentage. */
    percentage: Maybe<Scalars['Int']>;
    /** The discount type. */
    type: DiscountType;
};

/** The discount kind. */
export enum DiscountKind {
    /** Item discount. */
    Item = 'ITEM',
    /** Order discount. */
    Order = 'ORDER',
}

/** The discount summary. */
export type DiscountSummary = {
    __typename?: 'DiscountSummary';
    /** The discounted amount. */
    amount: Scalars['Float'];
    /** The coupon code of the discount. Only present if the discount is from a coupon. */
    couponCode: Maybe<Scalars['String']>;
    /** The text containing information about the discount. Only present if we have a matching language code. */
    description: Maybe<Scalars['String']>;
};

/** The discount type. */
export enum DiscountType {
    /** Coupons discount. */
    Coupons = 'COUPONS',
    /** Discounts discount. */
    Discounts = 'DISCOUNTS',
    /** Employee discount. */
    Employee = 'EMPLOYEE',
    /** Family discount. */
    Family = 'FAMILY',
    /** FamilyDiscounts discount. */
    FamilyDiscounts = 'FAMILY_DISCOUNTS',
    /** FamilyPrice discount. */
    FamilyPrice = 'FAMILY_PRICE',
    /** Manual discount. */
    Manual = 'MANUAL',
    /** Unknown discount. */
    Unknown = 'UNKNOWN',
}

/** The display unit. */
export type DisplayUnit = {
    __typename?: 'DisplayUnit';
    /** The imperial measurement details. */
    imperial: Maybe<Imperial>;
    /** The metric measurement details. */
    metric: Maybe<Metric>;
    /** The unit type of the package, e.g "Piece", "Meterware". */
    type: UnitType;
};

/** The family child item price. */
export type FamilyChildItemPrice = {
    __typename?: 'FamilyChildItemPrice';
    /** The discounts. */
    discounts: Maybe<Array<Discount>>;
    /** The sub total price excluding discount. */
    subTotalExclDiscount: Price;
    /** The sub total price including discount. */
    subTotalInclDiscount: Price;
    /** The family unit price. */
    unit: FamilyUnitPrice;
};

/** The family price. */
export type FamilyPrice = {
    __typename?: 'FamilyPrice';
    /** The assembly service price */
    assemblyService: Maybe<AssemblyServicePrice>;
    /** The discounts. */
    discounts: Maybe<Array<Discount>>;
    /** The sub total discount. */
    subTotalDiscount: Savings;
    /** The sub total price excluding discount. */
    subTotalExclDiscount: Price;
    /** The sub total price including discount. */
    subTotalInclDiscount: Price;
    /** The sub total savings excluding discount. */
    subTotalSavingsExclDiscount: Savings;
    /** The sub total savings including discount. */
    subTotalSavingsInclDiscount: Savings;
    /** The family unit price. */
    unit: FamilyUnitPrice;
};

/** The family total price. */
export type FamilyTotalPrice = {
    __typename?: 'FamilyTotalPrice';
    /** The discount summaries. */
    discountSummaries: Array<Maybe<DiscountSummary>>;
    /**
     * The proximity.
     * @deprecated This is an experimental feature that can be removed without notice. Use with caution.
     */
    proximity: Maybe<Array<Maybe<Proximity>>>;
    /** The total assembly service price. */
    totalAssemblyService: Maybe<TotalAssemblyServicePrice>;
    /** The total assembly service discount. */
    totalAssemblyServiceDiscount: Maybe<TotalAssemblyServiceDiscount>;
    /** The total discount. */
    totalDiscount: Savings;
    /** The total price excluding discount. */
    totalExclDiscount: Price;
    /** The total price including discount. */
    totalInclDiscount: Price;
    /** The total product price excluding discount. */
    totalProductExclDiscount: Maybe<Price>;
    /** The total product price including discount. */
    totalProductInclDiscount: Maybe<Price>;
    /** The total savings details. */
    totalSavingsDetails: SavingsDetails;
    /** The total savings excluding discount. */
    totalSavingsExclDiscount: Savings;
    /** The total savings including discount. */
    totalSavingsInclDiscount: Savings;
};

/** The family unit price. */
export type FamilyUnitPrice = {
    __typename?: 'FamilyUnitPrice';
    /** The price to pay excluding tax. */
    exclTax: Scalars['Float'];
    /** The price to pay including tax. */
    inclTax: Maybe<Scalars['Float']>;
    /** The tax to pay. */
    tax: Maybe<Scalars['Float']>;
    /** Valid from (descriptive date field). */
    validFrom: Maybe<Scalars['String']>;
    /** Valid to (descriptive date field). */
    validTo: Maybe<Scalars['String']>;
};

/** The fees, e.g. "eco", "weee" */
export type Fees = {
    __typename?: 'Fees';
    /** ECO fee. */
    eco: Maybe<Scalars['Float']>;
    /** Fee for electrical equipment in EU (Waste Electrical and Electronic Equipment). */
    weee: Maybe<Scalars['Float']>;
};

/** The Image details of a product. */
export type Image = {
    __typename?: 'Image';
    /** The filename. */
    filename: Scalars['String'];
    /** The height. */
    height: Scalars['Int'];
    /** The quality. */
    quality: Scalars['String'];
    /** The type. */
    type: Scalars['String'];
    /** The url. */
    url: Scalars['String'];
    /** The width. */
    width: Scalars['Int'];
};

/** The imperial measurement details. */
export type Imperial = {
    __typename?: 'Imperial';
    /** The unit of measurement. */
    unit: Scalars['String'];
    /** The value of measurement. */
    value: Scalars['String'];
};

/** A cart item contains item and price information. */
export type Item = BaseItem & {
    __typename?: 'Item';
    /** The availability. */
    availability: Availability;
    /** A list of all child items for an item. */
    childItems: Array<ChildItem>;
    /** The family price. */
    familyPrice: Maybe<FamilyPrice>;
    /** The fees, e.g. "eco", "weee" */
    fees: Maybe<Fees>;
    /** Is the assembly service selected or not? */
    isAssemblyServiceSelected: Scalars['Boolean'];
    /** Is the item a family item or not? */
    isFamilyItem: Scalars['Boolean'];
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID'];
    /** The package weight of the items in the cart. For now we only provide the formatted metric weight. */
    packageWeight: Maybe<Scalars['String']>;
    /** Product information. */
    product: Product;
    /** The quantity of an item. */
    quantity: Scalars['Int'];
    /** The regular price. */
    regularPrice: RegularPrice;
    /** Type of item, e.g. "ART", "SPR". */
    type: ItemType;
};

/** The type of an item. */
export enum ItemType {
    /** ART type. */
    Art = 'ART',
    /** SPR type. */
    Spr = 'SPR',
}

/** The material information. */
export type Material = {
    __typename?: 'Material';
    /** The material parts. */
    parts: Maybe<Array<MaterialPart>>;
    /** The product type. (e.g. Cover for 2-seat section) */
    productType: Maybe<Scalars['String']>;
};

/** The material part information. */
export type MaterialPart = {
    __typename?: 'MaterialPart';
    /** The part material. (e.g. 100% wood) */
    material: Scalars['String'];
    /** The part name. (e.g. Frame:) */
    name: Maybe<Scalars['String']>;
};

/** The measurements. */
export type Measurement = {
    __typename?: 'Measurement';
    /** The imperial measurement value. */
    imperial: Maybe<Scalars['String']>;
    /** The metric measurement value. */
    metric: Maybe<Scalars['String']>;
};

/** The metric measurement details. */
export type Metric = {
    __typename?: 'Metric';
    /** The unit of measurement. */
    unit: Scalars['String'];
    /** The value of measurement. */
    value: Scalars['String'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type Mutation = {
    __typename?: 'Mutation';
    /**
     * Adds items to a cart.
     *
     * --- ERROR CODES ---
     * ASSEMBLY_SERVICE_INTERNAL_ERROR: Assembly services have an internal error.
     * ASSEMBLY_SERVICE_NOT_ENABLED: Assembly service is not enabled for this market.
     * ASSEMBLY_SERVICE_VALIDATION_FAILED: Assembly service validation failed.
     * ILLEGAL_QUANTITY: Illegal quantity in request.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_INPUT: Invalid input in request.
     * INVALID_ITEM_NUMBER: Invalid or unavailable item number(s) in request.
     * INVALID_LANGUAGE_CODE: Invalid language code for the retail.
     * INVALID_POSTAL_CODE: The postal code provided is not valid.
     * INVALID_RETAIL_ID: Retail ID is invalid.
     * ITEM_LIMIT_REACHED: Max item limit reached.
     * ITEM_NUMBER_NOT_UNIQUE: Identical item numbers in request.
     * MISSING_LANGUAGE_CODE: Product information requested without language code.
     * PLANNERS_DISABLED: Planners are not enabled for a specific market.
     * PLANNERS_INTERNAL_ERROR: Planners API has an internal error.
     * PLANNERS_ITEM_NOT_FOUND: Planners product information could not be found.
     * UNAVAILABLE_ITEM_REMOVED: Item in cart was removed due to unavailability.
     */
    addItems: Maybe<Cart>;
    /**
     * Adds planners.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_PLANNER_CODE: Invalid planner code(s) in the request.
     * PLANNER_CODE_NOT_UNIQUE: Identical planner codes in the request.
     * PLANNER_LIMIT_REACHED: Max planner limit reached.
     */
    addPlanners: Maybe<AddPlannersPayload>;
    /**
     * Clears coupon from a cart.
     *
     * --- ERROR CODES ---
     * ASSEMBLY_SERVICE_INTERNAL_ERROR: Assembly services have an internal error.
     * ASSEMBLY_SERVICE_NOT_ENABLED: Assembly service is not enabled for this market.
     * ASSEMBLY_SERVICE_VALIDATION_FAILED: Assembly service validation failed.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_CODE: Invalid language code for the retail.
     * INVALID_POSTAL_CODE: The postal code provided is not valid.
     * INVALID_RETAIL_ID: Retail ID is invalid.
     * MISSING_LANGUAGE_CODE: Product information requested without language code.
     * PLANNERS_DISABLED: Planners are not enabled for a specific market.
     * PLANNERS_INTERNAL_ERROR: Planners API has an internal error.
     * PLANNERS_ITEM_NOT_FOUND: Planners product information could not be found.
     * UNAVAILABLE_ITEM_REMOVED: Item in cart was removed due to unavailability.
     */
    clearCoupon: Maybe<Cart>;
    /**
     * Clear employee discount agreed terms on cart.
     *
     * --- ERROR CODES ---
     * ASSEMBLY_SERVICE_INTERNAL_ERROR: Assembly services have an internal error.
     * ASSEMBLY_SERVICE_NOT_ENABLED: Assembly service is not enabled for this market.
     * ASSEMBLY_SERVICE_VALIDATION_FAILED: Assembly service validation failed.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_CODE: Invalid language code for the retail.
     * INVALID_POSTAL_CODE: The postal code provided is not valid.
     * INVALID_RETAIL_ID: Retail ID is invalid.
     * MISSING_LANGUAGE_CODE: Product information requested without language code.
     * PLANNERS_DISABLED: Planners are not enabled for a specific market.
     * PLANNERS_INTERNAL_ERROR: Planners API has an internal error.
     * PLANNERS_ITEM_NOT_FOUND: Planners product information could not be found.
     * UNAVAILABLE_ITEM_REMOVED: Item in cart was removed due to unavailability.
     */
    clearEmployeeDiscountAgreedTerms: Maybe<Cart>;
    /**
     * Clears all items in a cart.
     *
     * --- ERROR CODES ---
     * ASSEMBLY_SERVICE_INTERNAL_ERROR: Assembly services have an internal error.
     * ASSEMBLY_SERVICE_NOT_ENABLED: Assembly service is not enabled for this market.
     * ASSEMBLY_SERVICE_VALIDATION_FAILED: Assembly service validation failed.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_CODE: Invalid language code for the retail.
     * INVALID_POSTAL_CODE: The postal code provided is not valid.
     * INVALID_RETAIL_ID: Retail ID is invalid.
     * MISSING_LANGUAGE_CODE: Product information requested without language code.
     * PLANNERS_DISABLED: Planners are not enabled for a specific market.
     * PLANNERS_INTERNAL_ERROR: Planners API has an internal error.
     * PLANNERS_ITEM_NOT_FOUND: Planners product information could not be found.
     * UNAVAILABLE_ITEM_REMOVED: Item in cart was removed due to unavailability.
     */
    clearItems: Maybe<Cart>;
    /**
     * Clears planners.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     */
    clearPlanners: Maybe<ClearPlannersPayload>;
    /**
     * Copy the contents of a cart to another cart.
     *
     * Items that don't exist in the destination cart will be copied over with their quantity.
     * Items that do already exist will have their quantity increased.
     * If both carts have a coupon the source coupon will override the destination one.
     *
     * --- ERROR CODES ---
     * ASSEMBLY_SERVICE_INTERNAL_ERROR: Assembly services have an internal error.
     * ASSEMBLY_SERVICE_NOT_ENABLED: Assembly service is not enabled for this market.
     * ASSEMBLY_SERVICE_VALIDATION_FAILED: Assembly service validation failed.
     * CART_NOT_FOUND: The source cart was not found.
     * INVALID_LANGUAGE_CODE: Invalid language code for the retail.
     * INVALID_POSTAL_CODE: The postal code provided is not valid.
     * INVALID_RETAIL_ID: Retail ID is invalid.
     * INVALID_USER_ID: The source user id is not valid.
     * INTERNAL_ERROR: An internal error has occurred.
     * MISSING_LANGUAGE_CODE: Product information requested without language code.
     * PERMISSION_DENIED: The user does not have permission to copy cart from the source.
     * PLANNERS_DISABLED: Planners are not enabled for a specific market.
     * PLANNERS_INTERNAL_ERROR: Planners API has an internal error.
     * PLANNERS_ITEM_NOT_FOUND: Planners product information could not be found.
     * UNAVAILABLE_ITEM_REMOVED: Item in cart was removed due to unavailability.
     */
    copyCart: Maybe<Cart>;
    /**
     * Copy the items from one cart to another.
     *
     * --- ERROR CODES ---
     * ASSEMBLY_SERVICE_INTERNAL_ERROR: Assembly services have an internal error.
     * ASSEMBLY_SERVICE_NOT_ENABLED: Assembly service is not enabled for this market.
     * ASSEMBLY_SERVICE_VALIDATION_FAILED: Assembly service validation failed.
     * CART_NOT_FOUND: The source cart was not found.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_CODE: Invalid language code for the retail.
     * INVALID_POSTAL_CODE: The postal code provided is not valid.
     * INVALID_RETAIL_ID: Retail ID is invalid.
     * INVALID_USER_ID: The source user id is not valid.
     * ITEM_LIMIT_REACHED: Max item limit reached.
     * MISSING_LANGUAGE_CODE: Product information requested without language code.
     * PERMISSION_DENIED: The user does not have permission to copy items from the source.
     * PLANNERS_DISABLED: Planners are not enabled for a specific market.
     * PLANNERS_INTERNAL_ERROR: Planners API has an internal error.
     * PLANNERS_ITEM_NOT_FOUND: Planners product information could not be found.
     * UNAVAILABLE_ITEM_REMOVED: Item in cart was removed due to unavailability.
     */
    copyItems: Maybe<Cart>;
    /**
     * Removes items from a cart.
     *
     * --- ERROR CODES ---
     * ASSEMBLY_SERVICE_INTERNAL_ERROR: Assembly services have an internal error.
     * ASSEMBLY_SERVICE_NOT_ENABLED: Assembly service is not enabled for this market.
     * ASSEMBLY_SERVICE_VALIDATION_FAILED: Assembly service validation failed.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_CODE: Invalid language code for the retail.
     * INVALID_POSTAL_CODE: The postal code provided is not valid.
     * INVALID_RETAIL_ID: Retail ID is invalid.
     * ITEM_NUMBER_NOT_FOUND: Item number(s) could not be found.
     * MISSING_LANGUAGE_CODE: Product information requested without language code.
     * PLANNERS_DISABLED: Planners are not enabled for a specific market.
     * PLANNERS_INTERNAL_ERROR: Planners API has an internal error.
     * PLANNERS_ITEM_NOT_FOUND: Planners product information could not be found.
     * UNAVAILABLE_ITEM_REMOVED: Item in cart was removed due to unavailability.
     */
    removeItems: Maybe<Cart>;
    /**
     * Removes planners.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_PLANNER_CODE: Invalid planner code(s) in the request.
     * PLANNER_CODE_NOT_UNIQUE: Identical planner codes in the request.
     * PLANNER_LIMIT_REACHED: Max planner limit reached.
     * PLANNER_NOT_FOUND: The planner could not be found.
     */
    removePlanners: Maybe<RemovePlannersPayload>;
    /**
     * Replace the current cart with another cart.
     *
     * --- ERROR CODES ---
     * ASSEMBLY_SERVICE_INTERNAL_ERROR: Assembly services have an internal error.
     * ASSEMBLY_SERVICE_NOT_ENABLED: Assembly service is not enabled for this market.
     * ASSEMBLY_SERVICE_VALIDATION_FAILED: Assembly service validation failed.
     * CART_NOT_FOUND: The source cart was not found.
     * INVALID_LANGUAGE_CODE: Invalid language code for the retail.
     * INVALID_POSTAL_CODE: The postal code provided is not valid.
     * INVALID_RETAIL_ID: Retail ID is invalid.
     * INVALID_USER_ID: The source user id is not valid.
     * INTERNAL_ERROR: An internal error has occurred.
     * MISSING_LANGUAGE_CODE: Product information requested without language code.
     * PERMISSION_DENIED: The user does not have permission to replace cart from the source.
     * PLANNERS_DISABLED: Planners are not enabled for a specific market.
     * PLANNERS_INTERNAL_ERROR: Planners API has an internal error.
     * PLANNERS_ITEM_NOT_FOUND: Planners product information could not be found.
     * UNAVAILABLE_ITEM_REMOVED: Item in cart was removed due to unavailability.
     */
    replaceCart: Maybe<Cart>;
    /**
     * Set assembly service on item.
     * --- ERROR CODES ---
     * ASSEMBLY_SERVICE_INTERNAL_ERROR: Assembly services have an internal error.
     * ASSEMBLY_SERVICE_NOT_ENABLED: Assembly service is not enabled for this market.
     * ASSEMBLY_SERVICE_VALIDATION_FAILED: Assembly service validation failed.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_INPUT: One or more of the articles provided are invalid.
     * INVALID_ITEM_NUMBER: Invalid item or unavailable number(s) in request.
     * INVALID_POSTAL_CODE: The postal code provided is not valid.
     * INVALID_RETAIL_ID: Retail ID is invalid.
     * ITEM_NUMBER_NOT_UNIQUE: Identical item numbers in request.
     * PLANNERS_DISABLED: Planners are not enabled for a specific market.
     * PLANNERS_INTERNAL_ERROR: Planners API has an internal error.
     * PLANNERS_ITEM_NOT_FOUND: Planners product information could not be found.
     */
    setAssemblyService: Maybe<Cart>;
    /**
     * Set coupon on cart.
     * --- ERROR CODES ---
     * ASSEMBLY_SERVICE_INTERNAL_ERROR: Assembly services have an internal error.
     * ASSEMBLY_SERVICE_NOT_ENABLED: Assembly service is not enabled for this market.
     * ASSEMBLY_SERVICE_VALIDATION_FAILED: Assembly service validation failed.
     * COUPON_EXPIRED: The coupon code has already expired.
     * COUPON_NOT_STARTED: The discount associated with the coupon code isn't active yet.
     * COUPON_REDEEMED: The coupon has already been redeemed for the cart.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_COUPON_CODE: The coupon code is invalid.
     * INVALID_LANGUAGE_CODE: Invalid language code for the retail.
     * INVALID_POSTAL_CODE: The postal code provided is not valid.
     * INVALID_RETAIL_ID: Retail ID is invalid.
     * MISSING_LANGUAGE_CODE: Product information requested without language code.
     * PLANNERS_DISABLED: Planners are not enabled for a specific market.
     * PLANNERS_INTERNAL_ERROR: Planners API has an internal error.
     * PLANNERS_ITEM_NOT_FOUND: Planners product information could not be found.
     * UNAVAILABLE_ITEM_REMOVED: Item in cart was removed due to unavailability.
     */
    setCoupon: Maybe<Cart>;
    /**
     * Set employee discount agreed terms on cart.
     *
     * --- ERROR CODES ---
     * ASSEMBLY_SERVICE_INTERNAL_ERROR: Assembly services have an internal error.
     * ASSEMBLY_SERVICE_NOT_ENABLED: Assembly service is not enabled for this market.
     * ASSEMBLY_SERVICE_VALIDATION_FAILED: Assembly service validation failed.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_CODE: Invalid language code for the retail.
     * INVALID_POSTAL_CODE: The postal code provided is not valid.
     * INVALID_RETAIL_ID: Retail ID is invalid.
     * MISSING_LANGUAGE_CODE: Product information requested without language code.
     * PERMISSION_DENIED: The user is not an employee. Is required that the user links his account.
     * PLANNERS_DISABLED: Planners are not enabled for a specific market.
     * PLANNERS_INTERNAL_ERROR: Planners API has an internal error.
     * PLANNERS_ITEM_NOT_FOUND: Planners product information could not be found.
     * UNAVAILABLE_ITEM_REMOVED: Item in cart was removed due to unavailability.
     */
    setEmployeeDiscountAgreedTerms: Maybe<Cart>;
    /**
     * Update items.
     *
     * --- ERROR CODES ---
     * ASSEMBLY_SERVICE_INTERNAL_ERROR: Assembly services have an internal error.
     * ASSEMBLY_SERVICE_NOT_ENABLED: Assembly service is not enabled for this market.
     * ASSEMBLY_SERVICE_VALIDATION_FAILED: Assembly service validation failed.
     * ILLEGAL_QUANTITY: Illegal quantity in request.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_INPUT: Invalid input in request.
     * INVALID_ITEM_NUMBER: Invalid item or unavailable number(s) in request.
     * INVALID_LANGUAGE_CODE: Invalid language code for the retail.
     * INVALID_POSTAL_CODE: The postal code provided is not valid.
     * INVALID_RETAIL_ID: Retail ID is invalid.
     * ITEM_NUMBER_NOT_UNIQUE: Identical item numbers in request.
     * MISSING_LANGUAGE_CODE: Product information requested without language code.
     * PLANNERS_DISABLED: Planners are not enabled for a specific market.
     * PLANNERS_INTERNAL_ERROR: Planners API has an internal error.
     * PLANNERS_ITEM_NOT_FOUND: Planners product information could not be found.
     * UNAVAILABLE_ITEM_REMOVED: Item in cart was removed due to unavailability.
     */
    updateItems: Maybe<Cart>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationAddItemsArgs = {
    address: InputMaybe<AddressInput>;
    items: Array<AddItemInput>;
    languageCode: InputMaybe<Scalars['String']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationAddPlannersArgs = {
    input: AddPlannersInput;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationClearCouponArgs = {
    address: InputMaybe<AddressInput>;
    languageCode: InputMaybe<Scalars['String']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationClearEmployeeDiscountAgreedTermsArgs = {
    address: InputMaybe<AddressInput>;
    languageCode: InputMaybe<Scalars['String']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationClearItemsArgs = {
    address: InputMaybe<AddressInput>;
    languageCode: InputMaybe<Scalars['String']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationCopyCartArgs = {
    address: InputMaybe<AddressInput>;
    languageCode: InputMaybe<Scalars['String']>;
    sourceUserId: Scalars['ID'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationCopyItemsArgs = {
    address: InputMaybe<AddressInput>;
    languageCode: InputMaybe<Scalars['String']>;
    sourceUserId: Scalars['ID'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationRemoveItemsArgs = {
    address: InputMaybe<AddressInput>;
    itemNos: Array<Scalars['ID']>;
    languageCode: InputMaybe<Scalars['String']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationRemovePlannersArgs = {
    input: RemovePlannersInput;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationReplaceCartArgs = {
    address: InputMaybe<AddressInput>;
    languageCode: InputMaybe<Scalars['String']>;
    merge: InputMaybe<Scalars['Boolean']>;
    sourceUserId: Scalars['ID'];
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationSetAssemblyServiceArgs = {
    address: AddressInput;
    isSelected: Scalars['Boolean'];
    itemNos: Array<Scalars['ID']>;
    languageCode: InputMaybe<Scalars['String']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationSetCouponArgs = {
    address: InputMaybe<AddressInput>;
    code: Scalars['String'];
    languageCode: InputMaybe<Scalars['String']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationSetEmployeeDiscountAgreedTermsArgs = {
    address: InputMaybe<AddressInput>;
    agreed: Scalars['Boolean'];
    languageCode: InputMaybe<Scalars['String']>;
};

/**
 * The mutation type defines GraphQL operations that change data on the server.
 *
 * Note! For query responses, please make sure to specify only the data fields which you need to consume in
 * your application, otherwise we will waste bandwidth and you will have to wait while we fetch unused
 * resources.
 */
export type MutationUpdateItemsArgs = {
    address: InputMaybe<AddressInput>;
    items: Array<UpdateItemInput>;
    languageCode: InputMaybe<Scalars['String']>;
};

/** The planner. */
export type Planner = {
    __typename?: 'Planner';
    /** An alphanumeric 6 character length code. */
    code: Scalars['String'];
    /**
     * The date when the Planner was created. Format is ISO 8601 and the time zone is coordinated universal
     * time (UTC), YYYY-MM-DDThh:mm:ssZ, e.g. "2019-02-06T21:56:00Z".
     */
    created: Scalars['String'];
    /** The total family price. */
    familyTotalPrice: Maybe<PlannersFamilyTotalPrice>;
    /** The main design image. */
    image: Maybe<Scalars['String']>;
    /** A list of all items in the planner. */
    items: Array<Maybe<Item>>;
    /** The planner name (e.g. 'storageonepax'). */
    name: Scalars['String'];
    /** The total regular price. */
    regularTotalPrice: Maybe<PlannersRegularTotalPrice>;
};

/** The family total price for planners. */
export type PlannersFamilyTotalPrice = {
    __typename?: 'PlannersFamilyTotalPrice';
    /** The total discount. */
    totalDiscount: Savings;
    /** The total price excluding discount. */
    totalExclDiscount: Price;
    /** The total price including discount. */
    totalInclDiscount: Price;
};

/** Return type of Planners. */
export type PlannersPayload = {
    __typename?: 'PlannersPayload';
    /** The user's planners. */
    planners: Array<Planner>;
};

/** The regular total price for planners. */
export type PlannersRegularTotalPrice = {
    __typename?: 'PlannersRegularTotalPrice';
    /** The total discount. */
    totalDiscount: Savings;
    /** The total price excluding discount. */
    totalExclDiscount: Price;
    /** The total price including discount. */
    totalInclDiscount: Price;
};

/** The price. */
export type Price = {
    __typename?: 'Price';
    /** The price excluding tax. */
    exclTax: Scalars['Float'];
    /** The price including tax. */
    inclTax: Maybe<Scalars['Float']>;
    /** The tax. */
    tax: Maybe<Scalars['Float']>;
    /** A list of tax details. */
    taxDetails: Array<TaxDetail>;
};

/** The product information. */
export type Product = {
    __typename?: 'Product';
    /** The business structure. */
    businessStructure: Maybe<BusinessStructure>;
    /** The product category. */
    category: Maybe<Scalars['String']>;
    /** A short description of the product. */
    description: Maybe<Scalars['String']>;
    /** The display unit." */
    displayUnit: Maybe<DisplayUnit>;
    /** The energy efficiency symbol. */
    energyEfficiencySymbol: Maybe<Scalars['String']>;
    /** All energy labels. */
    energyLabels: Maybe<Array<Scalars['String']>>;
    /** Formatted item number (nn.nnn.nnn). */
    formattedItemNo: Scalars['String'];
    /** The global name. */
    globalName: Scalars['String'];
    /** The image details. */
    images: Array<Image>;
    /** Is assembly required? */
    isAssemblyRequired: Scalars['Boolean'];
    /** Is it a breath taking item? */
    isBreathTaking: Scalars['Boolean'];
    /** Is the item new? */
    isNew: Scalars['Boolean'];
    /** The materials information. */
    materials: Maybe<Array<Material>>;
    /** The measurements. */
    measurements: Array<Measurement>;
    /** The localised name. */
    name: Scalars['String'];
    /**
     * The technical details.
     * @deprecated Use with care! Subject for change due to technical debt in Selling Range Communications API.
     */
    technicalDetails: Maybe<TechnicalDetails>;
    /** The unit code, e.g. "piece", "meter" */
    unitCode: UnitCode;
    /** The water usage label. */
    waterLabel: Maybe<Scalars['String']>;
    /** The water usage symbol. */
    waterSymbol: Maybe<Scalars['String']>;
};

/** The Proximity. Ask SPE how this works. #sp-spe-general */
export type Proximity = {
    __typename?: 'Proximity';
    /**
     * The additional purchase.
     * @deprecated This is an experimental feature that can be removed without notice. Use with caution.
     */
    additionalPurchase: Maybe<AdditionalPurchase>;
    /**
     * The discount.
     * @deprecated This is an experimental feature that can be removed without notice. Use with caution.
     */
    discount: Maybe<ProximityDiscount>;
    /**
     * The discount id.
     * @deprecated This is an experimental feature that can be removed without notice. Use with caution.
     */
    discountID: Maybe<Scalars['String']>;
    /**
     * The discount name.
     * @deprecated This is an experimental feature that can be removed without notice. Use with caution.
     */
    discountName: Maybe<Array<Maybe<ProximityDiscountName>>>;
    /**
     * The discount validity.
     * @deprecated This is an experimental feature that can be removed without notice. Use with caution.
     */
    discountValidity: Maybe<ProximityDiscountValidity>;
    /**
     * Is order discount.
     * @deprecated This is an experimental feature that can be removed without notice. Use with caution.
     */
    isOrderDiscount: Maybe<Scalars['Boolean']>;
    /**
     * Is service discount.
     * @deprecated This is an experimental feature that can be removed without notice. Use with caution.
     */
    isServiceDiscount: Maybe<Scalars['Boolean']>;
};

/** The proximity discount. */
export type ProximityDiscount = {
    __typename?: 'ProximityDiscount';
    /**
     * The type.
     * @deprecated This is an experimental feature that can be removed without notice. Use with caution.
     */
    type: Maybe<Scalars['String']>;
    /**
     * The value.
     * @deprecated This is an experimental feature that can be removed without notice. Use with caution.
     */
    value: Maybe<Scalars['Float']>;
};

/** The proximity discount name. */
export type ProximityDiscountName = {
    __typename?: 'ProximityDiscountName';
    /**
     * The lang code.
     * @deprecated This is an experimental feature that can be removed without notice. Use with caution.
     */
    langCode: Maybe<Scalars['String']>;
    /**
     * The name
     * @deprecated This is an experimental feature that can be removed without notice. Use with caution.
     */
    name: Maybe<Scalars['String']>;
};

/** The Proximity discount validity */
export type ProximityDiscountValidity = {
    __typename?: 'ProximityDiscountValidity';
    /**
     * The end date.
     * @deprecated This is an experimental feature that can be removed without notice. Use with caution.
     */
    endDate: Maybe<Scalars['String']>;
    /**
     * The start date.
     * @deprecated This is an experimental feature that can be removed without notice. Use with caution.
     */
    startDate: Maybe<Scalars['String']>;
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please make sure to specify only the data fields which you need to consume in your application,
 * otherwise we will waste bandwidth and you will have to wait while we fetch unused resources.
 */
export type Query = {
    __typename?: 'Query';
    /**
     * Lookup a cart.
     *
     * --- ERROR CODES ---
     * ASSEMBLY_SERVICE_INTERNAL_ERROR: Assembly services have an internal error.
     * ASSEMBLY_SERVICE_NOT_ENABLED: Assembly service is not enabled for this market.
     * ASSEMBLY_SERVICE_VALIDATION_FAILED: Assembly service validation failed.
     * INTERNAL_ERROR: An internal error has occurred.
     * INVALID_LANGUAGE_CODE: Invalid language code for the retail.
     * INVALID_POSTAL_CODE: The postal code provided is not valid.
     * INVALID_RETAIL_ID: Retail ID is invalid.
     * MISSING_LANGUAGE_CODE: Product information requested without language code.
     * PLANNERS_DISABLED: Planners are not enabled for a specific market.
     * PLANNERS_INTERNAL_ERROR: Planners API has an internal error.
     * PLANNERS_ITEM_NOT_FOUND: Planners product information could not be found.
     * UNAVAILABLE_ITEM_REMOVED: Item in cart was removed due to unavailability.
     */
    cart: Maybe<Cart>;
    /**
     * Retrieve the user's planners.
     *
     * --- ERROR CODES ---
     * INTERNAL_ERROR: An internal error has occurred.
     */
    planners: Maybe<PlannersPayload>;
};

/**
 * The query type defines GraphQL operations that retrieve data from the server.
 *
 * Note! Please make sure to specify only the data fields which you need to consume in your application,
 * otherwise we will waste bandwidth and you will have to wait while we fetch unused resources.
 */
export type QueryCartArgs = {
    address: InputMaybe<AddressInput>;
    languageCode: InputMaybe<Scalars['String']>;
};

/** The regular child item price. */
export type RegularChildItemPrice = {
    __typename?: 'RegularChildItemPrice';
    /** The discounts. */
    discounts: Maybe<Array<Discount>>;
    /** The sub total price excluding discount. */
    subTotalExclDiscount: Price;
    /** The sub total price including discount. */
    subTotalInclDiscount: Price;
    /** The regular unit price. */
    unit: RegularUnitPrice;
};

/** The regular price details. */
export type RegularPrice = {
    __typename?: 'RegularPrice';
    /** The assembly service price */
    assemblyService: Maybe<AssemblyServicePrice>;
    /** The discounts. */
    discounts: Maybe<Array<Discount>>;
    /** The sub total discount. */
    subTotalDiscount: Savings;
    /** The sub total price excluding discount. */
    subTotalExclDiscount: Price;
    /** The sub total price including discount. */
    subTotalInclDiscount: Price;
    /** The regular unit price. */
    unit: RegularUnitPrice;
};

/** The regular total price. */
export type RegularTotalPrice = {
    __typename?: 'RegularTotalPrice';
    /** The discount summaries. */
    discountSummaries: Array<Maybe<DiscountSummary>>;
    /**
     * The proximity.
     * @deprecated This is an experimental feature that can be removed without notice. Use with caution.
     */
    proximity: Maybe<Array<Maybe<Proximity>>>;
    /** The total assembly service price. */
    totalAssemblyService: Maybe<TotalAssemblyServicePrice>;
    /** The total assembly service discount. */
    totalAssemblyServiceDiscount: Maybe<TotalAssemblyServiceDiscount>;
    /** The total discount. */
    totalDiscount: Savings;
    /** The total price excluding discount. */
    totalExclDiscount: Price;
    /** The total price including discount. */
    totalInclDiscount: Price;
    /** The total product price excluding discount. */
    totalProductExclDiscount: Maybe<Price>;
    /** The total product price including discount. */
    totalProductInclDiscount: Maybe<Price>;
    /** The total savings details. */
    totalSavingsDetails: SavingsDetails;
};

/** The regular unit price. */
export type RegularUnitPrice = {
    __typename?: 'RegularUnitPrice';
    /** The price to pay excluding tax. */
    exclTax: Scalars['Float'];
    /** The price to pay including tax. */
    inclTax: Maybe<Scalars['Float']>;
    /** Is the price a lower price item? */
    isLowerPrice: Scalars['Boolean'];
    /** Is the price a time restricted offer? */
    isTimeRestrictedOffer: Scalars['Boolean'];
    /** The previous price. */
    previousPrice: Maybe<Price>;
    /** The tax to pay. */
    tax: Maybe<Scalars['Float']>;
    /** Valid from (descriptive date field). */
    validFrom: Maybe<Scalars['String']>;
    /** Valid to (descriptive date field). */
    validTo: Maybe<Scalars['String']>;
};

/** Input for RemovePlanners. */
export type RemovePlannersInput = {
    /** An array of planner codes. Must be alphanumeric with a length of 6 characters. */
    codes: InputMaybe<Array<Scalars['String']>>;
};

/** Return type of RemovePlanners. */
export type RemovePlannersPayload = {
    __typename?: 'RemovePlannersPayload';
    /** The planners that were removed. */
    planners: Array<Planner>;
};

/** The Savings. */
export type Savings = {
    __typename?: 'Savings';
    /** The savings amount. */
    amount: Scalars['Float'];
};

/** The savings details. */
export type SavingsDetails = {
    __typename?: 'SavingsDetails';
    /** The coupons savings. */
    coupons: Scalars['Float'];
    /** The discounts savings. */
    discounts: Scalars['Float'];
    /** The employee savings. */
    employee: Scalars['Float'];
    /** The family savings. */
    family: Scalars['Float'];
    /** The family discount savings. */
    familyDiscounts: Scalars['Float'];
    /** The family price savings. */
    familyPrice: Scalars['Float'];
    /** The manual savings. */
    manual: Scalars['Float'];
    /** The voucher savings. */
    voucher: Scalars['Float'];
};

/** The tax details. */
export type TaxDetail = {
    __typename?: 'TaxDetail';
    /** The tax amount. */
    amount: Scalars['Float'];
    /** The tax rate. */
    rate: Scalars['Float'];
    /** The taxable amount. */
    taxableAmount: Scalars['Float'];
    /** The tax type. */
    type: Scalars['String'];
};

/** The technical compliance information. */
export type TechnicalCompliance = {
    __typename?: 'TechnicalCompliance';
    /**
     * The compliance title.
     * @deprecated Use with care! Subject for change due to technical debt in Selling Range Communications API.
     */
    title: Scalars['String'];
    /**
     * The compliance type.
     * @deprecated Use with care! Subject for change due to technical debt in Selling Range Communications API.
     */
    type: Scalars['String'];
    /**
     * The compliance value.
     * @deprecated Use with care! Subject for change due to technical debt in Selling Range Communications API.
     */
    value: Scalars['String'];
};

/** The technical details. */
export type TechnicalDetails = {
    __typename?: 'TechnicalDetails';
    /**
     * Compliance information
     * @deprecated Use with care! Subject for change due to technical debt in Selling Range Communications API.
     */
    compliance: Maybe<Array<TechnicalCompliance>>;
    /**
     * Image PDF url
     * @deprecated We're dropping support for pdf in favour of svg.
     */
    imagePdfURL: Scalars['String'];
    /**
     * Image url
     * @deprecated Use energyLabels under Product instead.
     */
    imageUrl: Scalars['String'];
    /**
     * Label PDF url
     * @deprecated We're dropping support for pdf in favour of svg.
     */
    labelPdfUrl: Scalars['String'];
    /**
     * Label url
     * @deprecated Use energyEfficiencyLabels under Product instead.
     */
    labelUrl: Scalars['String'];
};

/** The total assembly service discount. */
export type TotalAssemblyServiceDiscount = {
    __typename?: 'TotalAssemblyServiceDiscount';
    /** The total amount discounted off of the assembly service price */
    amount: Maybe<Scalars['Float']>;
};

/** The total assembly service price */
export type TotalAssemblyServicePrice = {
    __typename?: 'TotalAssemblyServicePrice';
    /** The price excluding tax. */
    exclTax: Maybe<Scalars['Float']>;
    /** The price including tax. */
    inclTax: Maybe<Scalars['Float']>;
    /** Is included in total price presentation */
    isIncluded: Scalars['Boolean'];
    /** The tax. */
    tax: Maybe<Scalars['Float']>;
    /** A list of tax details. */
    taxDetails: Maybe<Array<Maybe<TaxDetail>>>;
};

/** The unit code. */
export enum UnitCode {
    /** The meter type. */
    Meter = 'METER',
    /** The piece type. */
    Piece = 'PIECE',
}

/** The unit type. */
export enum UnitType {
    /** The area type. */
    Area = 'AREA',
    /** The meterware type. */
    Meterware = 'METERWARE',
    /** The multipack type. */
    Multipack = 'MULTIPACK',
    /** The piece type. */
    Piece = 'PIECE',
    /** The volume type. */
    Volume = 'VOLUME',
    /** The weight type. */
    Weight = 'WEIGHT',
    /** The weightware type. */
    Weightware = 'WEIGHTWARE',
}

/** The necessary input when updating an item. */
export type UpdateItemInput = {
    /** The IKEA item number, e.g. "00263850" */
    itemNo: Scalars['ID'];
    /** The item quantity. Quantity 0 will remove the item. */
    quantity: Scalars['Int'];
};

export type CartPropsFragment = {
    __typename?: 'Cart';
    context: {
        __typename?: 'Context';
        userId: string;
        isAnonymous: boolean;
        retailId: string;
    };
    items: Array<{
        __typename?: 'Item';
        itemNo: string;
        quantity: number;
        isFamilyItem: boolean;
        regularPrice: {
            __typename?: 'RegularPrice';
            unit: { __typename?: 'RegularUnitPrice'; exclTax: number };
        };
    }>;
};

export type ItemPropsFragment = {
    __typename?: 'Item';
    itemNo: string;
    quantity: number;
    isFamilyItem: boolean;
    regularPrice: {
        __typename?: 'RegularPrice';
        unit: { __typename?: 'RegularUnitPrice'; exclTax: number };
    };
};

export type AddItemsMutationVariables = Exact<{
    items: Array<AddItemInput> | AddItemInput;
    languageCode: InputMaybe<Scalars['String']>;
}>;

export type AddItemsMutation = {
    __typename?: 'Mutation';
    addItems: {
        __typename?: 'Cart';
        context: {
            __typename?: 'Context';
            userId: string;
            isAnonymous: boolean;
            retailId: string;
        };
        items: Array<{
            __typename?: 'Item';
            itemNo: string;
            quantity: number;
            isFamilyItem: boolean;
            regularPrice: {
                __typename?: 'RegularPrice';
                unit: { __typename?: 'RegularUnitPrice'; exclTax: number };
            };
        }>;
    } | null;
};

export const ItemPropsFragmentDoc = `
    fragment ItemProps on Item {
  itemNo
  quantity
  isFamilyItem
  regularPrice {
    unit {
      exclTax
    }
  }
}
    `;
export const CartPropsFragmentDoc = `
    fragment CartProps on Cart {
  context {
    userId
    isAnonymous
    retailId
  }
  items {
    ...ItemProps
  }
}
    `;
export const AddItemsDocument = `
    mutation AddItems($items: [AddItemInput!]!, $languageCode: String) {
  addItems(items: $items, languageCode: $languageCode) {
    ...CartProps
  }
}
    ${CartPropsFragmentDoc}
${ItemPropsFragmentDoc}`;
export const useAddItemsMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        AddItemsMutation,
        TError,
        AddItemsMutationVariables,
        TContext
    >
) =>
    useMutation<AddItemsMutation, TError, AddItemsMutationVariables, TContext>(
        ['AddItems'],
        useFetchData<AddItemsMutation, AddItemsMutationVariables>(
            AddItemsDocument
        ),
        options
    );
