import { useEffect } from 'react';

import { GetListsQuery, MinimalItem, MinimalList } from './reactQueryHooks';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';

export const useListSubscriptions = (
    data: GetListsQuery | undefined,
    reload: (
        options?: RefetchOptions
    ) => Promise<QueryObserverResult<GetListsQuery>>
) => {
    useEffect(() => {
        window?.ikea?.pubsub?.subscribe(
            'FAVOURITE_CLIENT/ADD_TO_FAVOURITES_SUCCESS',
            reload
        );
        window?.ikea?.pubsub?.subscribe(
            'FAVOURITE_CLIENT/REMOVE_FROM_FAVOURITES_SUCCESS',
            reload
        );
        return () => {
            window?.ikea?.pubsub?.unsubscribe(
                'FAVOURITE_CLIENT/ADD_TO_FAVOURITES_SUCCESS',
                reload
            );
            window?.ikea?.pubsub?.unsubscribe(
                'FAVOURITE_CLIENT/REMOVE_FROM_FAVOURITES_SUCCESS',
                reload
            );
        };
    }, []);

    // Send pubsub event when favourites is updated
    useEffect(() => {
        if (data) {
            const minimalLists: Array<MinimalList> = data.lists.map(list => {
                let items: Array<MinimalItem> = [];
                const { __typename, retail, store, ...minimalList } = list;
                if ('retail' in list) {
                    items = list.retail.items.map(({ itemNo, quantity }) => ({
                        itemNo,
                        quantity,
                    }));
                }
                return {
                    ...minimalList,
                    items,
                };
            });
            window?.ikea?.pubsub?.publish('TROJAN_AGENT/UPDATE_DATA', {
                data: minimalLists,
                source: 'favourites',
                store: 'favourites',
            });
        }
    }, [data]);
};
