import {
    GetListQuery,
    GetListsQuery,
    useGetListsQuery,
} from './reactQueryHooks';
import { useListSubscriptions } from './useListSubscriptions';

import useGetCommonVariables from 'apis/list/useGetCommonVariables';

import useAuth from 'hooks/useAuth2';

export type Lists = GetListsQuery['lists'];
export type List = GetListQuery['list'];

const useGetLists = () => {
    const { accessToken } = useAuth();
    const commonVariables = useGetCommonVariables();

    const { data, isLoading, refetch, isFetched } = useGetListsQuery(
        commonVariables,
        {
            enabled: !!accessToken,
            keepPreviousData: true,
        }
    );

    // Subscribes to pubsub
    useListSubscriptions(data, refetch);

    const lists = data?.lists;
    const currentList = lists && (lists.find(l => l.isSelected) || lists[0]);
    return {
        isLoading,
        currentList,
        lists: data?.lists,
        isFetched,
        refetch,
    };
};

export default useGetLists;
