import React, { RefObject } from 'react';

import IngkaHyperlink, { HyperlinkProps } from '@ingka/hyperlink';

const Hyperlink = ({
    children,
    ...props
}: HyperlinkProps & { ref?: RefObject<HTMLAnchorElement> }) => (
    <IngkaHyperlink prefix="list-ingka-" {...props}>
        {children}
    </IngkaHyperlink>
);

export default Hyperlink;
