import { getCookie, setCookie } from 'utils/cookie';

const publishHeaderEvent = () => {
    if (document.createEvent) {
        const event = document.createEvent('HTMLEvents');
        event.initEvent('addtocart', true, true);
        document.body.dispatchEvent(event);
    }
};

export default function (qty: number, country: string, language: string) {
    const cookieName = `IRMW_CART_COUNT_${country.toUpperCase()}`;
    const currentQty = parseInt(getCookie(cookieName), 10) || 0;
    setCookie(cookieName, country, language, `${currentQty + qty}`);
    publishHeaderEvent();
}
