import React, { HTMLAttributes, RefObject } from 'react';

import { ButtonProps } from '@ingka/button';
import { ChoiceItemProps } from '@ingka/choice';
import { PillProps } from '@ingka/pill';
import OriginalButton from '@skapa/Button';
import { ChoiceItem as OriginalChoiceItem } from '@skapa/Choice';
import OriginalPill from '@skapa/Pill';

type SkeletonProps = {
    showSkeleton: boolean;
    width?: number | string;
    height?: number | string;
    className?: string;
    children?: React.ReactNode;
    style?: React.CSSProperties;
};

const SkeletonElement: React.FC<
    SkeletonProps & {
        Element: React.ElementType;
    }
> = ({
    Element,
    children,
    className,
    width,
    height,
    showSkeleton,
    style,
    ...rest
}) => {
    const skeletonClass = showSkeleton ? 'list-ingka-skeleton' : '';
    const skeletonStyle = showSkeleton ? { width, height, ...style } : {};
    return (
        <Element
            className={
                className ? `${className} ${skeletonClass}` : `${skeletonClass}`
            }
            style={skeletonStyle}
            {...rest}
        >
            {showSkeleton ? '\u00a0' : children}
        </Element>
    );
};

export const H1: React.FC<SkeletonProps> = props => (
    <SkeletonElement {...props} Element="h1" />
);

export const H2: React.FC<SkeletonProps> = props => (
    <SkeletonElement {...props} Element="h2" />
);

export const H3: React.FC<SkeletonProps> = props => (
    <SkeletonElement {...props} Element="h3" />
);

export const P: React.FC<SkeletonProps> = props => (
    <SkeletonElement {...props} Element="p" />
);

export const LI: React.FC<SkeletonProps> = props => (
    <SkeletonElement {...props} Element="li" />
);

export const Fragment: React.FC<
    HTMLAttributes<HTMLDivElement> & SkeletonProps
> = props =>
    props.showSkeleton ? (
        <SkeletonElement {...props} Element="div" />
    ) : (
        <>{props.children}</>
    );

export const Button: React.FC<
    ButtonProps &
        SkeletonProps & {
            ref?: RefObject<HTMLAnchorElement | HTMLButtonElement>;
        }
> = ({
    ssrIcon,
    showSkeleton,
    text,
    width,
    onClick,
    style,
    ...buttonProps
}) => (
    <OriginalButton
        ssrIcon={ssrIcon}
        text={showSkeleton ? '' : text}
        onClick={onClick}
        disabled={showSkeleton}
        {...buttonProps}
        style={showSkeleton ? { width, ...style } : {}}
    />
);

export const Pill: React.FC<PillProps & SkeletonProps> = ({
    showSkeleton,
    label,
    width,
    onClick,
    ...pillProps
}) => (
    <OriginalPill
        label={showSkeleton ? '' : label}
        small
        disabled={showSkeleton}
        onClick={onClick}
        style={showSkeleton ? { width } : {}}
        {...pillProps}
    />
);

export const ChoiceItem: React.FC<ChoiceItemProps & SkeletonProps> = ({
    showSkeleton,
    title,
    width,
    onClick,
    ...choiceItemProps
}) => (
    <OriginalChoiceItem
        disabled={showSkeleton}
        title={title}
        onClick={onClick}
        style={showSkeleton ? { width } : {}}
        {...choiceItemProps}
    />
);
