import React, { ReactNode } from 'react';

interface GridProps {
    children: ReactNode;
}

const Grid = ({ children }: GridProps) => (
    <div className="list-ingka-grid">{children}</div>
);

export default Grid;
