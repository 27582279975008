import React from 'react';

import IngkaChoice, {
    ChoiceItemProps,
    ChoiceProps,
    ChoiceItem as IngkaChoiceItem,
} from '@ingka/choice';

const Choice = (props: ChoiceProps) => (
    <IngkaChoice prefix="list-ingka-" {...props} />
);

/* eslint-disable react/no-multi-comp */
export const ChoiceItem = (props: ChoiceItemProps) => (
    <IngkaChoiceItem prefix="list-ingka-" {...props} />
);

export default Choice;
